<script lang="ts" setup>
import { ref, computed, watch, provide } from 'vue'
import { breakpointsTailwind, useBreakpoints, useIntersectionObserver } from '@vueuse/core'
import maintainActiveSection from './maintainActiveSection'
import ProductDescriptionList, { type ProductDescriptionListItem } from './ProductDescriptionList.vue'
import DescriptionItem, {
  getItemDuration,
  type ProductDescriptionItemContent,
  type ProductId,
} from './DescriptionItems'
import { transitioning } from './stores'

// initialize this value so that the HTML contains at least one video element
const openIndex = ref(0)
const timeout = ref<ReturnType<typeof setTimeout> | undefined>()
const $section = ref<HTMLDivElement>()
const $items = ref<{ play?: () => void; stop?: () => void }[]>([])

const props = defineProps<{
  name: string
  items: (ProductDescriptionItemContent & ProductDescriptionListItem)[]
  productId: ProductId
  injection?: any
}>()

provide('injection', props.injection)

const current = computed(() => {
  const index = Math.max(openIndex.value, 0)
  return props.items[index]
})

const currentVideoDuration = computed(() => {
  return current.value ? getItemDuration(current.value) : 0
})

watch(openIndex, () => {
  playCurrent()
})

const { md } = useBreakpoints(breakpointsTailwind)

useIntersectionObserver($section, ([{ isIntersecting }]) => {
  if (!isIntersecting || !md.value || transitioning.value) {
    return // Don't play animations on mobile (or when transitioning)
  }

  if (openIndex.value === 0) {
    playCurrent()
  } else {
    // if the value change the "watch"
    // will take care of launching the video
    openIndex.value = 0
  }
})

maintainActiveSection($section, props.name)

function stopAnimation() {
  timeout.value && clearTimeout(timeout.value)
  timeout.value = undefined
  stopCurrent()
}

function playCurrent() {
  const $currentItem = $items.value[openIndex.value]
  if (typeof $currentItem?.play === 'function') {
    $currentItem.play()
  }
}

function stopCurrent() {
  const $currentItem = $items.value[openIndex.value]
  if (typeof $currentItem?.stop === 'function') {
    $currentItem.stop()
  }
}
</script>

<template>
  <div ref="$section" class="relative grid gap-[48px] lg:h-[443px] lg:grid-cols-[1fr_2fr] lg:pl-[16px]">
    <ProductDescriptionList
      v-model:open-index="openIndex"
      class="h-full shrink-0"
      :items="props.items"
      :current-video-duration="currentVideoDuration"
      :product-id="props.productId"
      current-item-blue
    />

    <div class="relative hidden grow lg:block xl:w-[783px]">
      <div
        v-for="(item, itemIndex) in props.items"
        :key="itemIndex"
        class="absolute left-0 right-0 transition-opacity duration-500"
        :aria-hidden="itemIndex !== openIndex"
        :class="{
          'opacity-0': itemIndex !== openIndex,
          'z-10': itemIndex === openIndex,
        }"
      >
        <DescriptionItem ref="$items" :item="item" :product-id="props.productId" class="shadow-asset" />
      </div>
    </div>
  </div>
</template>
