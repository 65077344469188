import GenericItem from './GenericItem.vue'
import { type ProductDescriptionItemVideo, sumDuration } from './VideoItem.vue'
import type { ProductDescriptionItemImage } from './ImageItem.vue'
import type { ProductDescriptionItemScreenshot } from './ScreenshotItem.vue'
import type { ProductDescriptionListItem } from '../ProductDescriptionList.vue'

export type { ProductId } from './CustomItem'

export type { ProductDescriptionItemVideo }

export interface ProductDescriptionItemCustom {
  itemId: string
  duration: number
}

export type ProductDescriptionItemContent =
  | ProductDescriptionItemVideo
  | ProductDescriptionItemImage
  | ProductDescriptionItemCustom
  | ProductDescriptionItemScreenshot

export default GenericItem

export function getItemDuration(cur: ProductDescriptionItemContent): number {
  if ('frames' in cur) {
    return sumDuration(cur.frames)
  }
  return cur.duration
}

export type ProductDescriptionItem = ProductDescriptionItemContent & ProductDescriptionListItem
